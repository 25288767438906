import React from 'react'
import './bootstrap-reboot.css'
import './App.css'
import { css, withStyles } from './withStyles'

class App extends React.Component {
  state = { slide: 1, preloading: true }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ preloading: false })
    }, 2000)
    setInterval(() => {
      const { slide } = this.state
      this.setState({ slide: slide === 5 ? 1 : slide + 1 })
    }, 5000)
  }

  render() {
    const { styles } = this.props
    const { slide, preloading } = this.state
    return (
      <div>
        {Array(preloading ? 1 : 5)
          .fill(0)
          .map((element, index) => (
            <div
              {...css(
                styles.background,
                {
                  backgroundImage: `url(images/${index + 1}.jpg)`
                },
                { opacity: slide === index + 1 ? 1 : 0 }
              )}
            />
          ))}
        <div {...css(styles.firstContainer)}>
          <div {...css(styles.secondContainer)}>
            <h1 {...css(styles.h1)}>LABEL</h1>
            <div {...css(styles.thirdContainer)}>
              <h2 {...css(styles.h2)}>
                Digital intelligence and creative solutions{' '}
              </h2>
              <h2 {...css(styles.h2, styles.titleSecondLine)}>
                for luxury brands and bespoke agencies
              </h2>
              <h2 {...css(styles.h2, styles.h2Mobile)}>
                Digital intelligence and creative solutions for luxury brands
                and bespoke agencies
              </h2>
              <div {...css(styles.buttonContainer)}>
                <a
                  href='mailto:connect@label.paris'
                  target='_blank'
                  rel='noopener noreferrer'
                  {...css(styles.button)}>
                  Connect
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(() => {
  return {
    firstContainer: {
      position: 'relative',
      height: '100vh',
      padding: 100,
      '@media (max-width: 600px)': {
        padding: 30
      }
    },
    secondContainer: {
      position: 'absolute',
      paddingRight: 30,
      bottom: 100
    },
    thirdContainer: {
      paddingLeft: 190,
      '@media (max-width: 600px)': {
        paddingLeft: 0
      }
    },
    titleSecondLine: {
      paddingLeft: 90,
      '@media (max-width: 600px)': {
        paddingLeft: 0
      }
    },
    h1: {
      fontSize: 72,
      textTransform: 'uppercase',
      letterSpacing: 5,
      marginBottom: 45,
      '@media (max-width: 600px)': {
        fontSize: 40,
        marginBottom: 20
      }
    },
    h2: {
      fontSize: 23,
      textTransform: 'none',
      fontWeight: 'normal',
      letterSpacing: 3,
      '@media (max-width: 600px)': {
        display: 'none'
      }
    },
    h2Mobile: {
      display: 'none',
      fontSize: 12,
      lineHeight: '18px',
      fontWeight: 'bold',
      '@media (max-width: 600px)': {
        display: 'block'
      }
    },
    background: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      background: 'url(images/1.jpg) no-repeat center 75% fixed',
      backgroundSize: 'cover',
      transition: 'opacity 500ms'
    },
    buttonContainer: {
      marginTop: 60,
      '@media (max-width: 600px)': {
        marginTop: 22
      }
    },
    button: {
      border: '2px solid white',
      padding: '20px 50px',
      cursor: 'pointer',
      color: 'white',
      transition:
        'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out',
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontSize: 19,
      fontWeight: 'normal',
      letterSpacing: 3,
      ':hover': {
        textDecoration: 'none',
        backgroundColor: 'white',
        color: 'black'
      },
      '@media (max-width: 600px)': {
        border: '1px solid white',
        fontSize: 12,
        letterSpacing: 1,
        padding: '10px 28px'
      }
    }
  }
})(App)
