import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet'
import aphroditeInterface from 'react-with-styles-interface-aphrodite/no-important'
import { css, withStyles } from 'react-with-styles'

import Theme from './Theme'

ThemedStyleSheet.registerTheme(Theme)
ThemedStyleSheet.registerInterface(aphroditeInterface)

export { css, withStyles, ThemedStyleSheet }
